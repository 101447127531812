import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import Process from "../../../static/images/triangular-process.webp";
import { Container } from "../../components/Container";

const processImage = css`
  ${tw`flex items-center justify-center max-w-prose mx-auto text-center mt-5`}
`;

const Banner: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Container {...rest}>
      <article css={processImage}>
        <img src={Process} alt={t("hero.processImageAlt")} tw="hidden sm:block mx-auto" />
      </article>
    </Container>
  );
};

export default Banner;
